<template>
    <span class="">
      <v-chip v-for="filter in filtersComponents" :key="filter.name"
        style="height: auto;"
        color="primary lighten-1"
        close-icon="mdi-close"
        class="mx-1 my-1 filters-chip" 
        outlined
        small
        :close="is_close && !filter.not_close_for_chip || filter.name == 'operator_group' && !filter.not_close_for_chip"
        @click="!not_open_filters && $emit('open-filters')"
        @click:close="deleteFilter(filter.name)"
      >
        <template v-if="filter.type == 'select'">
          <template v-if="filter.multiple && filter.filter_type == 'multiple-phones'">
            {{ filter.text }}: {{ parsMultiplyPhonesFilter(filter) }}
          </template>
          <template v-else-if="filter.multiple">
            {{ filter.text }}: {{ parsMultiplyFilter(filter) }}
          </template>
          <template v-else>
            <template v-if="filter.name == 'user_worker' || filter.name == 'user_recive' || filter.name == 'order_worker__worker'">
              {{ filter.text }}: {{ getFilterName(filter, 'last_name') }} 
            </template>
            <template v-else>
              {{ filter.text }}: {{ getFilterName(filter, 'name') }}
            </template>
          </template>
        </template>
        <template v-else-if="filter.name == 'street'">
          {{ filter.text }}: {{ filter.street_name }}
        </template>
        <template v-else-if="filter.type == 'checkbox'">
          {{ filter.text }}
        </template>
        <template v-else>
          {{ filter.text }}: {{ filter.value }}
        </template>
      </v-chip>
    </span>
</template>

<script>

export default {
    name: "filters-chips",
    props: ["filtersComponents", "is_close", "not_open_filters"], 

    data: () => ({
        //
    }),
    mounted(){
     
    },
    watch:{
      //
    },
    activated() {
      //
    },
    deactivated(){
      //
    },
    methods:{
      getFilterName(filter, field){
        let filterVal = filter.values.find(x => x.id == filter.value)
        if(field == 'last_name'){
          return filterVal && (filterVal.last_name || filterVal.first_name || filterVal.username ) || ''
        }else if(filter.name == 'debtor' || filter.name == 'letter'){
          return filter.value
        }
        return filterVal && filterVal[field] || ''
      },
      deleteFilter(filter_name){
        this.$emit('clear-filter', filter_name)
      },
      parsMultiplyFilter(filter){
        let result = ''
        let filterVal = ''
        if (!filter.value) return
        console.log("filter.value: ", filter.value)
        filter.value.forEach(element => {
          filterVal = filter.values.find(x => x.id == element)
          result = `${result}, ${filterVal && filterVal.name || ''}`
        });
        return result.replace(', ', '')
      },
      parsMultiplyPhonesFilter(filter){
        let result = ''
        if (!filter.value) return
        filter.value.forEach(element => {
          result = `${result}, ${element || ''}`
        });
        //console.log("filter-phones; ", filter)
        return result.replace(', ', '')
      }
    }
}
</script>
<style >
.filters-chip span.v-chip__content{
  white-space: break-spaces;
}
</style>