<template>
    <v-tooltip bottom color="secondary darken-1" content-class='custom-tooltip'>
        <template v-slot:activator="{ on, attrs }">
            <v-icon style="vertical-align: sub;"  class="ml-2" small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        
        <div v-if="tarif">
            
            <span>Інформація про тариф:</span>
            
            <div class="pa-0 ma-0">
                <v-divider class="my-2"/>
                <span>
                    <h3><v-icon  color="white" class="mr-1 pb-1">{{tarifService == 'tv' ? 'mdi-television' : 'mdi-web'}}</v-icon>
                    {{ tarif.name }}</h3>
                </span>
                <br>
                <div class="d-flex justify-space-between">
                    <span class="mr-auto">Вартість тарифу:</span> 
                    <span class="ml-5 text-end">{{ tarif.price }}</span>
                    <br>
                </div>
                <div v-if="tarif.speed" class="d-flex justify-space-between">
                    <span class="mr-auto">Швидкість:</span> 
                    <span class="ml-5 text-end">{{ tarif.speed }} Мб/с</span>
                    <br>
                </div>
                <div>
                    <v-chip color="accent" outlined small label v-if="tarif.promo" class="mr-2 mt-4">Акція</v-chip>
                    <v-chip color="accent" outlined small label v-if="tarif.is_special" class="mr-2 mt-4">Спеціальний</v-chip>
                    <v-chip color="accent" outlined small label v-if="tarif.service == 1 && tarif.speed" class="mr-2 mt-4">ПП</v-chip>
                    <v-chip color="accent" outlined small label v-if="tarif.package == 1 " class="mr-2 mt-4">УПП</v-chip>
                    <v-chip color="accent" outlined small label v-if="tarif.package == 2 " class="mr-2 mt-4">Стандарт</v-chip>
                    <v-chip color="accent" outlined small label v-if="tarif.real_ip" class="mr-2 mt-4">Real IP</v-chip> 
                </div>
            </div>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    props: ['tarif'],
    computed:{
        tarifService(){
            return this.tarif.speed && this.tarif.service == 2 && 'inet' || 'tv'
        }
    }
}
</script>

