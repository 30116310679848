<template>
	
	<div> 
		<keep-alive>
			<Filters ref="filtersCalls" v-on:apply-filters="checkGetCalls()" :for="type == 'page' ? 'calls' : 'calls_abonent'" />
		</keep-alive>
		<template>
			<v-menu 
			buttom 
			offset-y 
			z-index=1000 
			v-model="showMenu" 
			:position-x="x"
			:position-y="y"
			absolute
			>
				<v-list dense v-if="usersData && usersData.length != 0">
					<v-list-item three-line v-for="(user, index) of usersData" :key="index" :to="{ name: 'abonent_info', params: { id: user.id }}">
						<v-list-item-content>
							<v-list-item-title>{{ user.address }}</v-list-item-title>
							<v-list-item-subtitle>
									<span v-if="user.operator">({{ user.operator }})</span>
							</v-list-item-subtitle>
							<v-list-item-subtitle>{{ user.full_name }} </v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					
				</v-list>
				<v-list dense v-else>
					<v-list-item v-if="usersData && usersData.length == 0">
						<span  class="grey--text" >Абонента не знайдено</span>
					</v-list-item>
					
				</v-list>
			</v-menu> 
		</template>
		
		<v-row justify="center" class="">
			<v-col  >
				<keep-alive>
					<Search v-if="type == 'page'" v-on:apply-search="startSearch" :searchHint="searchHint" ref="searchPhones"/>
				</keep-alive>
				<v-data-table
					:headers="headers"
					loading-text="Завантаження..."
					no-data-text="Немає даних для відображення"
					:server-items-length="paginations.count"
					:items="calls"
					:options.sync="options"
					:page.sync="page"
					:loading="loading"
					:footer-props="footer"
					class="elevation-1 row-cursor"
					focusable
					disable-sort
					@click:row="onClickRow"
				>
					<template v-slot:top>

						<v-toolbar flat color="secondary lighten-1" dark :dense="type != 'page'">
							<v-toolbar-title :class="$vuetify.breakpoint.smAndDown && type=='page' ? 'subtitle-1' : ''">
								<h2 v-if="type=='page'" class="mr-6 accent--text">Дзвінки</h2>
								<h3 v-else class="mr-6 accent--text">Дзвінки</h3>
							</v-toolbar-title>

							<v-spacer />
							<template >
								<v-btn :disabled="loading || options.page <= 1" icon @click="page -= 1">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<span v-if="paginations.count && !$vuetify.breakpoint.xs" class="text-body-2">{{ options.page }} / {{ lastPage }} ( {{ paginations.count }} )</span> 
								<v-btn :disabled="loading || paginations.count == paginations.end_index" icon @click="page += 1">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
							</template>
						
							<v-spacer />
							
							
							<v-btn 
								icon 
								:loading="loading"
								@click="getCalls()">
								<v-icon>autorenew</v-icon>
							</v-btn>
							<v-btn  
								icon 
								:disabled="loading"
								@click="$refs.filtersCalls.show()"
							>
								<v-badge
								:value="countFilters > 0"
								color="accent"
								text="primary"
								:content="countFilters"
								dark
							>
								<v-icon>mdi-filter</v-icon>
								</v-badge>
							</v-btn>

						</v-toolbar>
						
					</template>
					<template
						v-if="countFilters > 0"
						v-slot:header="{ props: { headers } }"
					>
						<thead>
							<tr>
								<th :colspan="headers.length">
									Фільтри: 
									<keep-alive>
										<FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true" v-on:open-filters="$refs.filtersCalls.show()"/>
									</keep-alive>
								</th>
							</tr>
						</thead>
					</template>

				<!-- ---------------------------------------- Cols ----------------------------------------- -->

				<template #item.index="{ item }">
							<span>{{ calls.indexOf(item) + paginations.start_index }}</span>
				</template>

				<template #item.type_call="{ item }"> 
					<template v-if="type == 'page'">
						<v-btn :loading="loadingSearchAbonent && nowSearchPhone == item.phone_number" title="Знайти абонента по номеру" icon  @click.prevent="nowSearchPhone = item.phone_number" @click.stop="showMenuAbonentForCall">
							<v-icon v-if="item.is_incoming && !item.is_missed" color="blue">mdi-phone-incoming</v-icon>
							<v-icon v-if="item.is_missed" color="red">mdi-phone-missed</v-icon>
							<v-icon v-if="!item.is_incoming" color="green">mdi-phone-outgoing</v-icon>
						</v-btn>
					</template>
					<template v-else>
						<v-icon v-if="item.is_incoming && !item.is_missed" color="blue">mdi-phone-incoming</v-icon>
						<v-icon v-if="item.is_missed" color="red">mdi-phone-missed</v-icon>
						<v-icon v-if="!item.is_incoming" color="green">mdi-phone-outgoing</v-icon>
					</template>
						
					
				</template>
				<template #item.phone_number="{ item }"> 
					<!--   $store.commit('clipboard/updateBufferPhone', item.phone_number)    -->
					<span class="text-ex-space copy-phone-span" title="Копіювати номер" :class="clickCopyPhone == item.phone_number ? 'blue--text text-lighten-2' : ''" @click.stop="$router.app.copyText(item.phone_number), clickCopyPhone = item.phone_number" @mouseleave="clickCopyPhone = false"> 
						{{ item.phone_number }}
						<v-icon v-if="clickCopyPhone != item.phone_number" small  :class="$vuetify.breakpoint.mdAndUp ? 'copy-phone-icon-md' : ''" class=" ml-1"  color="grey">mdi-content-copy</v-icon>
						<v-icon v-else small  :class="$vuetify.breakpoint.mdAndUp ? 'copy-phone-icon-md' : ''" class=" ml-1"  color="blue lighten-2">mdi-content-save-check</v-icon>
						
					</span> 
				</template>
				
				<template #item.state_owner="{ item }">
							<span :title="item.state_owner">{{ getStateOwnerName(item.state_owner) }}</span>
				</template>
				<template #item.duration="{ item }">
					{{ item.duration && new Date(item.duration*1000).toUTCString().split(/ /)[4].replace('00:0', '').replace('00:', '') || ''}}
				</template>
				

				<!-- ---------------------------------------- /Cols ----------------------------------------- -->
				<template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
					{{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
				</template>
				</v-data-table> 
			</v-col>
			</v-row>
	</div>
</template>

<script>
	import axios from "axios"
	import {mapGetters} from 'vuex'
	import Filters from '@/components/Abonents/Filters.vue'
	import Search from '@/components/Abonents/Search.vue'
	import FiltersChips from '@/components/Abonents/FiltersChips.vue'

	export default {
		name: "abonent-tab-calls",
		props: ['abonent', "type"],
		components:{
			Filters,
			Search,
			FiltersChips
		},
		data: () => ({ 
			showMenu: false,
					x: 0,
					y: 0,
			loadingSearchAbonent: false,
			nowSearchPhone: '',
			clickCopyPhone: false,
			search: '',
			abonent_pcode: null,
			page: 1,
			updateTable: 0,
			searchHint: '',
			calls: [],
			paginations: {},
			countFilters: 0,
			loading: false,
			options: {},
			usersData: null,
			headers: [
				//{ text: '#', value: 'index', sortable: false,  width: 50},
				//{ text: '#', value: 'id', sortable: false,  width: 50},
				{ text: 'Тип', value: 'type_call', },
				{ text: 'Номер', value: 'phone_number', cellClass: 'phone-td' },
				{ text: 'Виклик', value: 'date_call', },
				{ text: 'Прийняв', value: 'state_owner', },
				{ text: 'Початок', value: 'date_start', },
				{ text: 'Кінець', value: 'date_end', },
				{ text: 'Тривалість', value: 'duration', }
			],
			footer: {
					"items-per-page-options": [10,25,50],
					"items-per-page-text": " ", //"Абонентів на сторінці:"
					"items-per-page-all-text": "Всі",
					"show-first-last-page": true,
					"show-current-page": true,
			},
		}),
		computed: {
			...mapGetters({
				call_centre_users: "users/call_centre_users",
				phoneBuffer: "clipboard/phoneBuffer"
			}),
			lastPage(){
				let rawLastPage = this.paginations.count / this.options.itemsPerPage
				return rawLastPage && Math.ceil(rawLastPage) || 1
			},
		},
		watch: {
			showMenu(){
				!this.showMenu && this.hideMenuAbonentForCall()
			},
			search: function(){
				this.checkGetCalls()
			},
			options: {
				handler (val, oldVal) {
					oldVal.page && this.getCalls();
				},
				deep: true,
			},
			'abonent.user_pcode': function (val) { 
				this.abonent_pcode = val
				val && this.createFilterPhones()
			},
		},
		mounted(){
			this.type == 'page' && this.checkGetCalls() //Якщо компонент завантажено зі сторінки загальніх дзвінків
		},
		activated() {
			this.calls = []
			if(this.abonent_pcode != this.abonent.user_pcode){ // Перевіряємо чи наявний абонент відрізняється від попереднього
				this.createFilterPhones()
				//this.checkGetCalls()
			}
		},
		deactivated(){
			//
		},
		methods: {
			onClickRow(item){
				if(this.type == 'page') this.$refs.searchPhones.searchPhones(item.phone_number)
			},
			checkGetCalls(){
				if(this.options.page == 1){
					this.getCalls()
				}else{
					this.page = 1;
				}
			},
			clearFilter(filter_name){
				this.$refs.filtersCalls.filters.find(x => x.name == filter_name).value = ''
				this.$refs.filtersCalls.apply()
			},
			getFiltersOfComponents(){
				return this.$refs.filtersCalls.filters.filter(x => x.value && x.value != '') 
			},
			filterFormat() {
				const temp = {};
				temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
				temp.itemsPerPage = this.options.itemsPerPage;
				temp.page = this.options.page;

				/* получение значений фильтрации и просчет колличества фильтров */
				this.filtersComponents = this.getFiltersOfComponents()
				this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
				this.countFilters = this.filtersComponents.length

				if(temp["state_owner"]){ //Фіча для унікальної передачі значень з селекту "Прийняв виклик" (замість id користувача - передаємо номер телефону)
					temp["state_owner"] = this.call_centre_users.find(x => x.id == temp["state_owner"]).call_centre
				}
				if(this.search){
					//console.log(this.searchParse(this.search))
					Object.assign(temp, {phone_number: this.search})
				} 

				return temp; 
			},
			
			hideMenuAbonentForCall(){
				this.showMenu = false
				this.usersData = null
			},
			async showMenuAbonentForCall(e) {
				e.preventDefault()
				this.showMenu = false
				this.loadingSearchAbonent = true
				await axios.get(`/api/cabletv/find_phones/?phone=${this.nowSearchPhone}`)
				.then((req) => {
					this.usersData = req.data.results
					this.loadingSearchAbonent = false
					this.x = e.clientX
					this.y = e.clientY
					this.$nextTick(() => {
						this.showMenu = true
					})
				})
			},
			getCalls(){
				this.loading = true;
				axios.get(`${this.$router.app.devUrlServiceCalls}/api_calls/get_calls/`, {params: {is_user: this.type != 'page',...this.filterFormat()}}) // this.$route.params.id
				.then( response =>{
					//console.log(response.data)
					this.calls = response.data.results
					this.paginations = response.data.paginations
				})
				.catch(() => {
					//console.log(error);
					//this.errored = true;
				})
				.finally(() => (this.loading = false)); 
			},
			getStateOwnerName(state_owner){
				let user = this.call_centre_users && this.call_centre_users.find(x => x.call_centre == state_owner)
				if(user){
					//return user.on_duty && 'Черговий' || user.name
					return user.on_duty && 'Черговий' || user.name
				}else {
					return state_owner
				}
			},
			createFilterPhones(){
				/** Додаємо у фільтри номери абонента*/

				if(this.abonent?.user_phones.length){
					let phonesFormat = []
					this.abonent?.user_phones.forEach(phone => {
						phonesFormat.push(phone.phone)
					})
					this.$refs.filtersCalls.filters.find(x => x.name == "phone_number").values = phonesFormat
					this.$refs.filtersCalls.filters.find(x => x.name == "phone_number").value = phonesFormat

					this.checkGetCalls() //Робимо запит на дзвінки абонента
				}else{
					this.$refs.filtersCalls.filters.find(x => x.name == "phone_number").values = []
					this.$refs.filtersCalls.filters.find(x => x.name == "phone_number").value = null
					this.filterFormat()
					this.calls = []
					this.paginations = {}
					
				}
				/**-------------------------------------- */
			},
			startSearch: function(search){
				this.search = search 
			},
		},
	}
</script>

	
<style scoped>
.text-ex-space{
	white-space: nowrap !important /* Запрещаем перенос слов */
}
.copy-phone-icon-md{
	visibility: hidden;
}
.copy-phone-span{
	padding-top: 10px;
	padding-bottom: 10px;
}
.copy-phone-span:hover > .copy-phone-icon-md{
	visibility: visible;
}
</style>